<template>
    <button :class="{'text-white bg-primary': light, 'text-primary': !light}"
            class="font-bold py-2 px-3 rounded-md inline-block float-right text-sm"
            @click="fetchUpdatedAssetsData"
    >
        <svg class="inline-block mr-1" fill="none" height="15" viewBox="0 0 21 18" width="21"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.7071 10.707C20.8946 10.5195 20.9999 10.2652 20.9999 10C20.9999 9.73484 20.8946 9.48053 20.7071 9.293L17.7071 6.293C17.5196 6.10553 17.2652 6.00021 17.0001 6.00021C16.7349 6.00021 16.4806 6.10553 16.2931 6.293L13.2931 9.293C13.1109 9.4816 13.0101 9.7342 13.0124 9.9964C13.0147 10.2586 13.1199 10.5094 13.3053 10.6948C13.4907 10.8802 13.7415 10.9854 14.0037 10.9877C14.2659 10.99 14.5185 10.8892 14.7071 10.707L16.0001 9.414V11C16.0001 11.2652 16.1054 11.5196 16.293 11.7071C16.4805 11.8946 16.7349 12 17.0001 12C17.2653 12 17.5197 11.8946 17.7072 11.7071C17.8947 11.5196 18.0001 11.2652 18.0001 11V9.414L19.2931 10.707C19.4806 10.8945 19.7349 10.9998 20.0001 10.9998C20.2652 10.9998 20.5196 10.8945 20.7071 10.707ZM8.70708 7.293C8.89455 7.48053 8.99987 7.73484 8.99987 8C8.99987 8.26516 8.89455 8.51947 8.70708 8.707L5.70708 11.707C5.51955 11.8945 5.26525 11.9998 5.00008 11.9998C4.73492 11.9998 4.48061 11.8945 4.29308 11.707L1.29308 8.707C1.19757 8.61475 1.12139 8.50441 1.06898 8.3824C1.01657 8.2604 0.988985 8.12918 0.987831 7.9964C0.986677 7.86362 1.01198 7.73194 1.06226 7.60905C1.11254 7.48615 1.18679 7.3745 1.28069 7.28061C1.37458 7.18671 1.48623 7.11246 1.60913 7.06218C1.73202 7.0119 1.8637 6.9866 1.99648 6.98775C2.12926 6.9889 2.26048 7.01649 2.38249 7.0689C2.50449 7.12131 2.61483 7.19749 2.70708 7.293L4.00008 8.586V7C4.00008 6.73478 4.10544 6.48043 4.29297 6.29289C4.48051 6.10536 4.73486 6 5.00008 6C5.2653 6 5.51965 6.10536 5.70719 6.29289C5.89472 6.48043 6.00008 6.73478 6.00008 7V8.586L7.29308 7.293C7.48061 7.10553 7.73492 7.00021 8.00008 7.00021C8.26525 7.00021 8.51955 7.10553 8.70708 7.293Z"
                :fill="light ? 'white':'#1565D8'"/>
            <path
                d="M17 10C17.2652 10 17.5196 10.1054 17.7071 10.2929C17.8946 10.4805 18 10.7348 18 11C18 12.4176 17.5695 13.8018 16.7656 14.9694C15.9617 16.137 14.8223 17.033 13.498 17.5389C12.1737 18.0448 10.7271 18.1367 9.34947 17.8024C7.97185 17.4682 6.72817 16.7235 5.783 15.667C5.69398 15.5694 5.62517 15.4552 5.58056 15.3308C5.53595 15.2065 5.51642 15.0745 5.52309 14.9426C5.52977 14.8107 5.56252 14.6814 5.61945 14.5622C5.67638 14.4429 5.75637 14.3362 5.85479 14.2481C5.95321 14.16 6.06811 14.0922 6.19286 14.0488C6.31761 14.0053 6.44973 13.987 6.5816 13.9949C6.71347 14.0028 6.84246 14.0368 6.96113 14.0948C7.0798 14.1528 7.1858 14.2338 7.273 14.333C7.94805 15.0879 8.83638 15.6199 9.82044 15.8588C10.8045 16.0977 11.8379 16.0322 12.7839 15.6709C13.7299 15.3096 14.5439 14.6696 15.1182 13.8355C15.6925 13.0014 16 12.0127 16 11C16 10.7348 16.1054 10.4805 16.2929 10.2929C16.4804 10.1054 16.7348 10 17 10ZM16.14 3.74503C16.3374 3.56799 16.4565 3.31977 16.4709 3.05497C16.4853 2.79018 16.394 2.53049 16.217 2.33303C15.2718 1.27652 14.0282 0.531871 12.6505 0.197617C11.2729 -0.136637 9.82626 -0.0447459 8.502 0.461132C7.17774 0.96701 6.03829 1.86303 5.23439 3.03065C4.43049 4.19826 4.00004 5.58244 4 7.00003C4 7.26525 4.10536 7.5196 4.29289 7.70714C4.48043 7.89467 4.73478 8.00003 5 8.00003C5.26522 8.00003 5.51957 7.89467 5.70711 7.70714C5.89464 7.5196 6 7.26525 6 7.00003C6.00002 5.98738 6.30751 4.99861 6.8818 4.16455C7.45608 3.3305 8.27008 2.69048 9.21608 2.32918C10.1621 1.96789 11.1955 1.90235 12.1796 2.14124C13.1636 2.38014 14.052 2.9122 14.727 3.66703C14.9039 3.8646 15.152 3.98382 15.4168 3.99845C15.6816 4.01307 15.9414 3.92192 16.139 3.74503H16.14Z"
                :fill="light ? 'white':'#1565D8'"/>
        </svg>
        <span v-if="!onlyIcon" class="text-md">Refresh</span>
    </button>
</template>

<script>
import Axios from "../../../../config/axios";

export default {
    name: "UpdateAssetsButtonComponent",
    props: {
        onlyIcon: {
            type: Boolean,
            default: false
        },
        light: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        fetchUpdatedAssetsData() {
            this.showLoader();
            Axios.get("/asset-logs/update")
                .then(() => this.$emit('assets-updated', true))
                .catch(err => console.log(err))
                .finally(() => this.hideLoader())
        }
    }
}
</script>

<style scoped>

</style>